<template>
  <div class="put-report">
    <div class="report-content">
<!--      <Head :head-info="headInfo" @headBack="goBack"></Head>-->
      <div class="report-tips">
        <p>
          您好！由于咨询人数较多，请在此反馈您的问题
          <br/>
          我司将于2个工作日内处理，感谢您的理解和支持
        </p>
      </div>
      <div class="problem-type">
        <div class="title">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/put_report_require.png">
          <span>问题反馈类型</span>
        </div>
        <div class="content">
          <template v-for="item in problemList">
            <div :class="[item.desc == activeProblem ? 'active' : '', 'wrapper']" :key="item.code"
                 @click="handleChooseProblem(item.desc)">{{ item.desc }}
            </div>
          </template>
        </div>
      </div>
      <div class="problem-desc">
        <div class="title">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/put_report_require.png">
          <span>问题描述</span>
        </div>
        <div class="desc">
          <van-field v-model="problemDesc" type="textarea" rows="6" border
                     placeholder="请描述您遇到了什么问题，我们会尽快和您联系…"></van-field>
        </div>
        <div class="title">
          <span>图片</span>
          <span class="image">（提供问题截图）</span>
        </div>
        <div class="upload-img">
          <van-uploader v-model="fileList" multiple :max-count="3" :after-read="afterRead"/>
        </div>
      </div>
      <div class="connection">
        <div class="title">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/put_report_require.png">
          <span>联系方式</span>
        </div>
        <div class="form">
          <van-field v-model="name" label="姓名" placeholder="请输入您的称呼"/>
          <van-field v-model="phoneC" type="number" label="手机号" placeholder="请输入手机号，方便与您联系"/>
        </div>
        <div class="button" @click="handleSubmit">
          <p>提交</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Field, Uploader, Toast } from 'vant'
import Head from '../../components/fullWebviewHead.vue'
import { _upload } from '../../utils/upload.js'

const api = require('../../utils/api.js').api

Vue.use(Field)
Vue.use(Toast)
Vue.use(Uploader)

export default {
  name: 'putReport',
  data () {
    return {
      headInfo: {},
      //问题反馈类型
      activeProblem: '我要退款',
      problemList: [],
      problemDesc: '',
      //图片上传
      fileList: [],
      upFileList: [],
      imgList: '',
      name: '',
      phone: '',
      phoneC: '',
      template: '',
      pi: ''
    }
  },
  components: {
    Head
  },
  created () {
    // this.headInfo.title = '投诉反馈'
    this.phone = this.$route.query.phone
    this.pi = this.$route.query.pi
    this.template = this.$route.query.template
    this.problemList = [
      {
        code: 1,
        desc: '我要退款'
      },
      {
        code: 2,
        desc: 'APP无法下载/无法打开'
      },
      {
        code: 3,
        desc: '支付问题'
      },
      {
        code: 4,
        desc: '返现或提现有误'
      },
      {
        code: 5,
        desc: '鲸选卡订单问题'
      },
      {
        code: 6,
        desc: '其它'
      }
    ]
  },
  mounted () {
  },
  methods: {
    /*
    顶部返回按钮
     */
    goBack () {
      this.$router.go(-1)
    },
    /*
    图片上传
     */
    afterRead (file) {
      let fileLength = file.length
      this.upFileList = file
      if (fileLength > 1) {
        this.upload(file[0], fileLength, 0)
      } else {
        this.upload(file, 1, 0)
      }
    },
    upload (file, length, index) {
      file.status = 'uploading'
      file.message = '上传中...'
      let len = 2
      const $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      let maxPos = $chars.length
      let pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
      }
      let name = file.file.name
      let pos = name.indexOf('.')
      let type = name.substr(pos)
      let fileName = `jingxuanshi/phoneRecharge/report/${Date.parse(String(new Date()))}` + pwd + type // 文件名称（时间戳+随机字符+后缀）
      _upload().multipartUpload(fileName, file.file).then(res => {
        // let imageUrlObj = {}
        // imageUrlObj.fileName = res.res.requestUrls[0]
        // imageUrlObj.fileUrl = res.res.requestUrls[0]
        // imageUrlObj.fileType = 1
        // imageUrlObj.type = 1
        // this.submitFile()  //调后端接口，将oss返回的数据传给后端存储倒库
        this.imgList = [...this.imgList, res.res.requestUrls[0]]
        file.status = ''
        file.message = ''
        if (index < length - 1) {
          this.upload(this.upFileList[index + 1], length, index + 1)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleChooseProblem (val) {
      this.activeProblem = val
    },
    /*
    提交联系方式
     */
    handleSubmit () {
      if (this.problemDesc == '') {
        Toast.fail('请填写问题描述')
        return
      } else if (this.name == '') {
        Toast.fail('请填写姓名')
        return
      } else if (this.phoneC == '') {
        Toast.fail('请填写手机号')
        return
      }
      this.$axios({
        url: api.putFeedback,
        method: 'post',
        params: {
          phone: this.phone,
          sceneStatus: 5,
          templateId: this.pi,
          template: this.template
        },
        data: {
          subject: this.activeProblem,
          content: this.problemDesc,
          image: this.imgList ? this.imgList.join(',') : '',
          nickname: this.name,
          phone: this.phoneC
        }
      }).then(res => {
        Toast.success('举报成功，请耐心等待回复')
        setTimeout(() => {
          this.goBack()
        }, 1000)
      }).catch(err => {
        console.info(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.put-report {
  min-height: 100vh;
  background: #F4F4F4;

  .report-content {
    .report-tips {
      width: 100%;
      height: 40px;
      background: #FBF4E2;

      & > p {
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        color: #C99040;
        line-height: 20px;
      }
    }

    .problem-type,
    .problem-desc,
    .connection {
      padding: 12px 0;
      background: #FFFFFF;

      .title {
        margin: 0 20px;

        & > img {
          position: relative;
          top: -5px;
          width: 8px;
          height: 8px;
        }

        & > span {
          margin-left: 8px;
          font-size: 18px;
          font-weight: 800;
          color: #333333;
          line-height: 25px;
        }

        .image {
          margin-left: 0;
          text-align: left;
          font-size: 15px;
          color: #999999;
          line-height: 21px;
        }
      }
    }

    .problem-type {
      .content {
        display: flex;
        flex-wrap: wrap;
        margin: 4px 20px 0;

        .wrapper {
          margin-top: 8px;
          margin-left: 8px;
          padding: 0 10px;
          height: 28px;
          background: #F4F4F4;
          border-radius: 4px;
          font-size: 15px;
          color: #999999;
          line-height: 28px;
        }

        .active {
          background: #FBF4E0;
          color: #C99040;
        }
      }
    }

    .problem-desc {
      margin-top: 8px;

      .desc {
        .van-field {
          margin: 8px auto 15px;
          border-radius: 10px;
          border: 1px solid #E2E2E2;
          width: 335px;
        }
      }

      .upload-img {
        margin: 8px 20px 0;
      }
    }

    .connection {
      margin-top: 8px;

      .form {
        margin: 8px 20px 0;
      }

      .button {
        margin: 22px auto 0;
        width: 330px;
        height: 50px;
        background: linear-gradient(146deg, #FFE687 0%, #FFC667 100%);
        border-radius: 10px;

        & > p {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #7F694E;
          line-height: 50px;
        }
      }
    }
  }
}
</style>
