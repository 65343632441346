<template>
  <div class="full-webview" :style="{background: headInfo.bgColor, color: headInfo.fontColor}">
    <div class="left">
      <van-icon name="arrow-left" @click="back"/>
    </div>
    <div class="middle">
      <p :style="{color: headInfo.fontColor}">{{ headInfo.title }}</p>
    </div>
    <div class="right">
      <p :style="{color: headInfo.fontColor}" @click="showRule">{{ headInfo.rightText }}</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {Icon} from 'vant'

Vue.use(Icon)
export default {
  name: 'fullWebviewHead',
  data(){
    return{}
  },
  props: {
    headInfo: Object
  },
  created () {
  },
  mounted () {
  },
  methods: {
    /*
    返回
     */
    back(){
      this.$emit('headBack')
    },
    /*
    规则
     */
    showRule(){
      this.$emit('headRule')
    }
  }
}
</script>

<style lang="less" scoped>
.full-webview{
  position: relative;
  width: 375px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  .left{
    width: 80px;
    .van-icon{
      margin-left: 8px;
      font-size: 22px;
    }
  }
  .middle{
    & > p{
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
    }
  }
  .right{
    margin-right: 14px;
    width: 80px;
    & > p{
      text-align: right;
      font-size: 15px;
      line-height: 21px;
    }
  }
}
</style>
